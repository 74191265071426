.section {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: center;
    position: relative;
    img {
      position: relative;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
    .copy {
      h2 {
        position: relative;
        z-index: 1;
        font-size: 1.4rem;
        font-weight: bold;
        color: #fff;
        text-align: center;
        @media (min-width: 768px) {
          font-size: 3rem;
        }
      }
    }
    .downarrow {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-image: url("../../public/images/downarrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 40px;
      height: 40px;
      z-index: 2;
    }
  }