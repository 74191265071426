.shopify {
    background-image: url("../../../../public/images/shopify_glyph_white.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
    z-index: 2;
  }

  h1, h2 {
    font-family: 'CalSans', sans-serif;
  }