@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'CalSans';
  src: local('CalSans'), url(./../public/fonts/CalSans-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Binlay';
  src: local('Binlay'), url(./../public/fonts/Binlay.otf) format('opentype');
}
@font-face {
  font-family: 'Holingston';
  src: local('Holingston'), url(./../public/fonts/Holingston.otf) format('opentype');
}
@layer base {
  .text-edge-outline {
    -webkit-text-stroke: 1px rgba(255,255,255,0.3);
  }
} 

body {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

label.fake-link {
  @apply text-zinc-500;
  &:hover {
    cursor: pointer;
  }
}